<template>
    <!-- Expanded Details Events Page -->
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow: scroll;">
		<EditAppointmentModal @cancelEdit="cancelEditAppointment" @updated="updatedAppointment" />
		<a-modal
            :visible="slotModal.visible"
			:zIndex="999"
            @cancel="onClose"
            :footer="null"
            :bodyStyle="{padding: '30px', overflow: 'scroll'}"
			class="events-modal"
			width="80vw"
            >
			<template v-if="slotModal.slot && slotModal.slot.meetingDetails">
				<BHLoading :show="slotLoading" />
				<h5 class="mb-5">Event Slot Details</h5>

				<div class="dF">
					<!-- Participant Section -->
					<a-card :title="`Participants (${slotModal.slot.meetingDetails.participants.length}/${slotModal.slot.eventGroup.meetingSettings.spots})`" style="width:280px;">
						<div v-for="(lead,leadI) in slotModal.slot.meetingDetails.participants" class="dF aC" :class="leadI > 0 ? 'mt-3' : ''">
							<a-avatar>{{getInitials(lead.name)}}</a-avatar>
							<div @click="viewContact(lead)" class="f1 ml-2" style="cursor: pointer; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
								<strong>{{lead.name}}</strong>&nbsp;&nbsp;<span style="color: var(--orange);" :title="lead.email"><{{lead.email}}></span>
							</div>
						</div>
					</a-card>

					<!-- Queue Section -->
					<a-card class="ml-4 f1" :title="`Queue (${calculateQueue.length})`">
						<a-icon @click="refreshQueue" slot="extra" type="reload" style="cursor: pointer;"></a-icon>
						<template v-if="calculateQueue.length">
							<div v-for="(lead,leadI) in calculateQueue" class="dF aC" :class="leadI > 0 ? 'mt-3' : ''">
								<a-avatar>{{getInitials(lead.name)}}</a-avatar>
								<div class="f1 dF aC jSB ml-2">
									<div @click="viewContact(lead)" style="cursor: pointer; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 280px;">
										<strong>{{lead.name}}</strong>&nbsp;&nbsp;<span style="color: var(--orange);"><{{lead.email}}></span>
									</div>
									<a-button @click="createAppointment(lead)" type="primary">Create Appointment</a-button>
								</div>
							</div>
						</template>
						<template v-else>
							The queue is currently empty...
						</template>
					</a-card>
				</div>

				<div class="dF">
					<a-card class="mt-4 f1" :title="`Active Appointments (${calculateActiveQueue.length})`">
						<template v-if="calculateActiveQueue.length">
							<div v-for="(lead,leadI) in calculateActiveQueue" class="dF aC" :class="leadI > 0 ? 'mt-3' : ''">
								<a-avatar>{{getInitials(lead.name)}}</a-avatar>
								<div class="f1 dF aC jSB ml-2">
									<div @click="viewContact(lead)" style="cursor: pointer; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 280px;">
										<strong>{{lead.name}}</strong>&nbsp;&nbsp;<span style="color: var(--orange);"><{{lead.email}}></span>
									</div>
									<div class="dF aC">
										<a-button @click="previewAppointment(lead)">Preview Appointment</a-button>
										<a-button @click="editAppointment(lead)" class="ml-3" type="primary">Edit Appointment</a-button>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							There are currently no active appointments...
						</template>
					</a-card>
				</div>
			</template>
		</a-modal>

		<a-modal
            :visible="limitModal.visible"
            @cancel="onCloseLimit"
            :footer="null"
			class="events-modal"
			centered
            >
			<template v-if="limitModal.visible">
				<BHLoading :show="limitLoading" />
				<div style="text-align: center;">
					<h5 class="mb-4" style="font-weight: bold;">Invitee Limit</h5>

					<div class="dF jC">
						<a-row>
							<a-col :span="24">
								<a-form-model-item label="Edit the invitee limit for this event slot">
									<a-input-number :min="1" v-model="limitModal.newLimit" size="large" />
								</a-form-model-item>
							</a-col>
						</a-row>
					</div>
					<div class="mt-1 mb-4">
						(There is currently {{limitModal.slot.meetingDetails.participants.length}} participant(s) in this event slot)
					</div>
				</div>
			</template>
			<div style="text-align: center;">
                <a-button @click="onCloseLimit" class="mt-3 cancel-button mr-3" size="large" style="width: 125px;">CANCEL</a-button>
                <a-button @click="onSubmitLimit" style="width: 125px;" size="large" type="primary">APPLY</a-button>
            </div>
		</a-modal>

        <BHLoading :show="loading" />
        <div class="mb-3" style="display: inline-block;">
            <div style="display: inline-block; cursor: pointer;">
                <div @click="$router.push('/events')" class="dF">
                    <a-icon class="mr-4" style="font-size: 1.75rem;" type="arrow-left" />
					<h3>Back to All Events</h3>
                </div>
            </div>
        </div>

		<a-tabs default-active-key="1" @change="changeView">
			<a-tab-pane key="1" tab="Active Events">
				<div class="dF h-full">
					<template v-if="events.length">
						<div class="w-full">
							<div v-for="(slot,slotI) in reformatEventSlots">
								<h3 class="mt-4">{{ slotI }}</h3>
								<a-table :pagination="false" :title="null" class="white-table" :columns="columns" :dataSource="slot">
									<div slot="time" slot-scope="obj">{{convertTime(obj.startTime)}} - {{convertTime(obj.endTime)}}</div>
									<div slot="participants" slot-scope="obj">{{obj.meetingDetails.participants.length}} / {{obj.spots}}</div>
									<div slot="queue" slot-scope="obj">{{obj.queueLeads.length}}</div>
									<div slot="actions" slot-scope="obj" class="dF" style="justify-content: flex-end;">
										<a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
											<div slot="content">
												<div @click="viewSlot(obj)" class="popoverContent dF aC"><a-icon type="eye" class='mr-2' /><div>View Slot</div></div>
												<div @click="editLimit(obj)" class="popoverContent dF aC"><a-icon type="edit" class='mr-2' /><div>Edit Invitee Limit</div></div>
												<div @click="downloadSlotCSV(obj)" class="popoverContent dF aC"><a-icon type="download" class='mr-2' /><div>Download CSV</div></div>
												<div @click="archiveSlot(obj)" class="popoverContent dF aC"><a-icon type="inbox" class='mr-2' /><div>Archive Slot</div></div>
												<div @click="deleteSlot(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete Slot</div></div>
											</div>
											<div class="more-option-icon mr-4">
												<a-icon style="line-height: 40px;" type="more" />
											</div>
										</a-popover>
									</div>
								</a-table>
							</div>
						</div>
					</template>
					<template v-else>
						<EmptyApp :text="'You don\'t have any active events'" :img="require('@/assets/emptyIcons/appointments.png')" />
					</template>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Archived Events">
				<div class="dF h-full">
					<template v-if="events.length">
						<div class="w-full">
							<div v-for="(slot,slotI) in reformatEventSlots">
								<h3 class="mt-4">{{ slotI }}</h3>
								<a-table :pagination="false" :title="null" class="white-table" :columns="columns" :dataSource="slot">
									<div slot="time" slot-scope="obj">{{convertTime(obj.startTime)}} - {{convertTime(obj.endTime)}}</div>
									<div slot="participants" slot-scope="obj">{{obj.meetingDetails.participants.length}} / {{obj.eventGroup.meetingSettings.spots}}</div>
									<div slot="queue" slot-scope="obj">{{obj.queueLeads.length}}</div>
									<div slot="actions" slot-scope="obj" class="dF" style="justify-content: flex-end;">
										<a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
											<div slot="content">
												<div @click="viewSlot(obj)" class="popoverContent dF aC"><a-icon type="eye" class='mr-2' /><div>View Slot</div></div>
												<div @click="downloadSlotCSV(obj)" class="popoverContent dF aC"><a-icon type="download" class='mr-2' /><div>Download CSV</div></div>
												<div @click="unarchiveSlot(obj)" class="popoverContent dF aC"><a-icon type="download" class='mr-2' /><div>Unarchive Slot</div></div>
												<div @click="deleteSlot(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete Slot</div></div>
											</div>
											<div class="more-option-icon mr-4">
												<a-icon style="line-height: 40px;" type="more" />
											</div>
										</a-popover>
									</div>
								</a-table>
							</div>
						</div>
					</template>
					<template v-else>
						<EmptyApp :text="'You don\'t have any archived events'" :img="require('@/assets/emptyIcons/appointments.png')" />
					</template>
				</div>
			</a-tab-pane>
		</a-tabs>
    </div>
</template>

<script>
    import BHLoading from 'bh-mod/components/common/Loading'
	import EditAppointmentModal from '@/components/contacts/EditAppointmentModal'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import moment from 'moment'

    export default {
        components:{
            BHLoading,EmptyApp,EditAppointmentModal
        },
        data() {
            return {
				slotModal:{
					visible:false,
					slot:{}
				},
				limitModal:{
					visible:false,
					newLimit:0,
					slot:{}
				},
                events:[],
                loading:false,
				slotLoading:false,
				limitLoading:false,
				columns:[
					{ title: 'Time', key: 'time', scopedSlots: { customRender: 'time' },},
					{ title: 'Participants', key: 'participants', scopedSlots: { customRender: 'participants' },},
					{ title: 'Queue', key: 'queue', scopedSlots: { customRender: 'queue' },},
					{ title: '', key: 'actions', scopedSlots: { customRender: 'actions' },},
				],
            }
        },
        watch:{
            '$route':{
                immediate:true,
                async handler(val){
                    if (val.params && val.params.id && val.params.id != '') {
                        console.log('EVENT ID', val.params.id)
						this.loading = true;
						this.$api.get(`/event-groups/event-slot/:instance/${val.params.id}`).then( ({data}) => {
							console.log('Event slot data coming back', data)
							this.events = data
							this.loading = false;
						})
                    }
                }
            },
        },
        computed:{
			reformatEventSlots(){
				console.log('Reformatting event slots...')
				let events = this.events;
				events.sort((a,b) => {
					let aDate = new Date(a.startTime);
					let bDate = new Date(b.startTime);
					return aDate - bDate;
				})

				let reformattedData = {};

				events.forEach(item => {
					let date = this.convertDate(item.startTime);
					if (!reformattedData[date]){
						reformattedData[date] = []
					}

					reformattedData[date].push(item)
				});
				console.log('Reformatted Event Slots: ', reformattedData)
				return reformattedData;
			},
			calculateQueue(){
				let queueLeads = JSON.parse(JSON.stringify(this.slotModal.slot.queueLeads))
				queueLeads = queueLeads.filter(x => !x.appointment)
				return queueLeads
			},
			calculateActiveQueue(){
				let queueLeads = JSON.parse(JSON.stringify(this.slotModal.slot.queueLeads))
				queueLeads = queueLeads.filter(x => x.appointment)
				return queueLeads
			},
			timeFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeFormat ? this.$store.state.contacts.allSettings.user.options.regional.timeFormat : 12
			},
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
			timezone(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
			},
            allContacts() {
                return this.$store.state.contacts.allContacts
            },
            externalContacts() {
                return this.$store.state.contacts.externalContacts
            }
        },
        methods:{
            moment,
			updatedAppointment(){
				console.log('UPDATE APPOINTMENT RAN')
				this.refreshQueue()
			},
			cancelEditAppointment(){
				this.$store.commit('CLOSE_APPOINTMENT')
				this.refreshQueue()
			},
			changeView(key){
				console.log('Changing View...', key)
				if (key == '1'){
					this.events = []
					this.loading = true;
					this.$api.get(`/event-groups/event-slot/:instance/${this.$route.params.id}`).then( ({data}) => {
						console.log('Event slot data coming back', data)
						this.events = data
						this.loading = false;
					})
				} else if (key == '2'){
					this.events = []
					this.loading = true;
					this.$api.get(`/event-groups/event-slot/:instance/${this.$route.params.id}?archived=true`).then( ({data}) => {
						console.log('Event slot data coming back', data)
						this.events = data
						this.loading = false;
					})
				}
			},
			editLimit(obj){
				console.log('Edit Limit...', obj)
				let currentMax = obj.spots
				this.limitModal = {
					visible: true,
					newLimit: currentMax || 0,
					slot: obj
				}
			},
			onSubmitLimit(){
				console.log('Applying Limit...')
				let slotId = this.limitModal.slot.id
				let sendObj = {
					spots: this.limitModal.newLimit
				}
				this.limitLoading = true
				this.$api.put(`/event-slots/:instance/${slotId}`, sendObj).then( ({data}) => {
					this.events = this.events.map(x => {
						if (x.id == data.id) return data
						else return x
					})
					this.limitLoading = false
					this.$message.success('Invitee Limit updated successfully.')
					this.limitModal = {
						visible: false,
						newLimit: 0,
						slot: {}
					}
				}).catch(err => {
					this.limitLoading = false
					this.$message.error('There was an error updating the limit.')
				})
			},
			previewAppointment(lead){
				console.log('Previewing Appointment...', lead)
				//get appointment
				this.slotLoading = true
				this.$api.get(`/appointments/:instance/${lead.appointment}`).then( ({data}) => {
					this.slotLoading = false
					this.$store.commit('OPEN_PREVIEW_MODAL', {type:'appointment', object:data})
				}).catch(err => {
					this.slotLoading = false
					this.$message.error('There was an error previewing the appointment.')
				})
			},
			editAppointment(lead){
				console.log('Editing Appointment...', lead)
				this.slotLoading = true
				this.$api.get(`/appointments/:instance/${lead.appointment}`).then( ({data}) => {
					this.slotLoading = false
					this.$store.commit('EDIT_APPOINTMENT', data)
					if (data.contact && data.contact.id) {
						setTimeout(() => {
							this.$store.commit('UPDATE_QUERY_CONTACT', data.contact)
						}, 50)
					}
				}).catch(err => {
					this.slotLoading = false
					this.$message.error('There was an error editing the appointment.')
				})
			},
			createAppointment(lead){
				console.log('Creating Appointment...', lead)

				this.slotLoading = true

				let numDate = new Date(this.slotModal.slot.startTime)
				let month = numDate.getMonth()+1
				let day = numDate.getDate()
				let year = numDate.getFullYear()
				let time = numDate.getTime()
				time = this.moment(time).format('HH:mm')

				let finalCreatedDate = year+'-'+month+'-'+day+' '+time

				let meetingType = '';
				let location = '';
				if (this.slotModal.slot.eventGroup.meetingSettings.preferredEventType == 'virtual'){
					meetingType = 'virtualmeeting';
				} else if (this.slotModal.slot.eventGroup.meetingSettings.preferredEventType == 'physical'){
					meetingType = 'salescentre';
					location = this.slotModal.slot.eventGroup.meetingSettings.location;
				}

				let sendObj = {
					type: meetingType,
					title: "1-on-1 Meeting: " + this.slotModal.slot.eventGroup.title,
					assignTo: [this.$store.state.user.user.id],
					contact: lead.contact,
					guests: [],
					location: location,
					reminderType: "none",
					reminderOn: 0,
					notes: "",
					link: "",
					relatedType: "contact",
					queueLead: lead.id
				}

				sendObj.startTime = parseInt(this.moment(this.slotModal.slot.startTime).format('X'))*1000
				sendObj.endTime = parseInt(this.moment(this.slotModal.slot.endTime).format('X'))*1000

				sendObj.date = parseInt(this.moment(finalCreatedDate).format('X'))*1000
				sendObj.date = sendObj.date + this.offsetDiffMs('set')
				sendObj.startTime = sendObj.startTime + this.offsetDiffMs('set')
				sendObj.endTime = sendObj.endTime + this.offsetDiffMs('set')

				console.log('SEND OBJ', sendObj)

				this.$api.post(`/appointments/:instance`, sendObj).then(({data}) => {
					this.$store.commit('ADD_APPOINTMENT',data)
					this.refreshQueue()
					this.$message.success('Appointment created successfully.')
				}).catch(err => {
					this.slotLoading = false
					this.$message.error('There was an error creating the appointment.')
				})
			},
			refreshQueue(){
				console.log('Refreshing Queue...')
				this.slotLoading = true;
				this.$api.get(`/event-slots/:instance/${this.slotModal.slot.id}`).then( ({data}) => {
					console.log('Event slot data coming back', data)
					this.slotModal.slot = data
					this.events = this.events.map(x => {
						if (x.id == data.id) return data
						else return x
					})
					this.slotLoading = false;
				}).catch( err => {
					this.slotLoading = false;
				})
			},
			viewContact(lead){
				let email = lead.email
				let find = this.slotModal.slot.participantDetails.find(x => x.email == email)
				if (find){
					this.$router.push(`/leads/${find.contactId}`)
				} else {
					this.$message.error('This contact is not in your database.')
				}
			},
			downloadSlotCSV(obj){
				console.log('Downloading Slot CSV...', obj)
				this.loading = true
				let rows = [
					["name", "email"],
				];
				obj.participantDetails.forEach(x => {
					rows.push([x.name, x.email])
				})

				let csvContent = "data:text/csv;charset=utf-8,"
					+ rows.map(e => e.join(",")).join("\n");

				var encodedUri = encodeURI(csvContent);
				var link = document.createElement("a");
				link.setAttribute("href", encodedUri);
				link.setAttribute("download", `${obj.eventGroup.title}-${this.convertTime(obj.startTime)}-${this.convertTime(obj.endTime)}.csv`);
				document.body.appendChild(link);

				link.click();
				this.loading = false
			},
			getInitials(name){
				let initials = name.match(/\b\w/g) || [];
				initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
				return initials
			},
			viewSlot(obj){
				console.log('Viewing Slot:', obj)
				this.slotModal = {
					visible: true,
					slot: obj
				}
			},
			archiveSlot(obj){
				console.log('Archiving Slot:', obj)
				this.loading = true;
				this.$api.put(`/event-slots/:instance/${obj.id}`, {status:'archived'}).then( ({data}) => {
					this.events = this.events.filter(x => x.id !== obj.id)
					this.loading = false;
				}).catch(err => {
					this.$message.error('There was an error archiving the event slot.')
					this.loading = false;
				})
			},
			unarchiveSlot(obj){
				console.log('Unarchiving Slot:', obj)
				this.loading = true;
				this.$api.put(`/event-slots/:instance/${obj.id}`, {status:null}).then( ({data}) => {
					this.events = this.events.filter(x => x.id !== obj.id)
					this.loading = false;
				}).catch(err => {
					this.$message.error('There was an error unarchiving the event slot.')
					this.loading = false;
				})
			},
			onClose(){
				this.slotModal = {
					visible: false,
					slot: {}
				}
			},
			onCloseLimit(){
				this.limitModal = {
					visible: false,
					newLimit: 0,
					slot: {}
				}
			},
			offsetDiffMs(type){
				let desiredTimezone = moment.tz(this.timezone);
				let desiredOffset = desiredTimezone.utcOffset();
				let currentOffset = new Date().getTimezoneOffset() * -1;
				let offsetDiff = 0
				let offsetDiffMs = 0
				if (currentOffset !== desiredOffset){
					if (type == 'set'){
						offsetDiff = currentOffset - desiredOffset;
					} else {
						offsetDiff = desiredOffset - currentOffset;
					}
					offsetDiffMs = offsetDiff * 60 * 1000;
				}
				return offsetDiffMs
			},
			convertDate(num){
				let x = new Date(num)
				//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
				x.setTime(x.getTime() + this.offsetDiffMs('get'));
				let year = x.getFullYear()
				let month = x.getMonth()+1
				let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
			},
			convertTime(time){
				let x = new Date(time)
				x.setTime(x.getTime() + this.offsetDiffMs('get'));
				let hours = x.getHours()
				let minutes = x.getMinutes()
				minutes = minutes < 10 ? '0'+minutes : minutes

				let strTime = hours + ':' + minutes

				if (this.timeFormat == 12){
					let ampm = hours >= 12 ? 'PM' : 'AM'
					hours = hours % 12
					hours = hours ? hours : 12 // the hour '0' should be '12'
					strTime = hours + ':' + minutes + ' ' + ampm
				}
				return strTime
			},
			convertDate(num){
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            },
			deleteSlot(obj){
				if (this.$p < 40 && (!obj.createdBy || obj.createdBy.id !== this.$store.state.user.user.id)){
					return this.$message.error('You do not have permission to delete Event Slots')
				}
				let self = this
				this.$confirm({
					title: 'Delete Event Slot',
					content: h => <div>Do you want to delete this event slot?</div>,
					okText: 'DELETE',
					okType: 'danger',
					cancelText: 'CANCEL',
					centered: true,
					onOk(){
						console.log('Deleting Event Slot...')
						self.loading = true;
						self.$api.delete(`/event-slots/:instance/${obj.id}`).then( ({data}) => {
							self.events = self.events.filter(x => x.id !== obj.id)
							self.loading = false;
						}).catch(err => {
							self.$message.error('There was an error deleting the event slot.')
							self.loading = false;
						})
					},
					onCancel(){
						console.log('CANCELED')
					}
				})
			}
        },
		created(){

		}
    }
</script>

<style lang="scss" scoped>
    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }


</style>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
	.events-modal .ant-modal {
		padding-bottom: 0;
	}
</style>
